// @file Common enums that we use

import { isAppUsing } from '@@/bits/flip'
import { p__, __ } from '@@/bits/intl'
import { MagicTemplate } from '@@/types'

enum HttpCode {
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  UnprocessableEntity = 422,
  RateLimitError = 429,
  ServerError = 500,
  Conflict = 409,
  Accepted = 202,
  Ok = 200,
}

// Keeps track of error codes in app/services/api_error_code.rb
enum ApiErrorCode {
  ACTIVE_LIBRARY_ONLY = 'ACTIVE_LIBRARY_ONLY',
  EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
  SUBDOMAIN_ALREADY_IN_USE = 'SUBDOMAIN_ALREADY_IN_USE',
  INVALID_ATTRIBUTE = 'INVALID_ATTRIBUTE',
  INVALID_EMAIL = 'INVALID_EMAIL',
  INVALID_EMAIL_DOMAIN = 'INVALID_EMAIL_DOMAIN',
  INVALID_TOKEN = 'INVALID_TOKEN',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  MISSING_ATTRIBUTE = 'MISSING_ATTRIBUTE',
  NOT_UNIQUE = 'NOT_UNIQUE',
  BACKPACK_ONLY = 'BACKPACK_ONLY',
  NO_USER = 'NO_USER',
  RECORD_INVALID = 'RECORD_INVALID',
  SUSPENDED_ACCOUNT = 'SUSPENDED_ACCOUNT',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  PASSWORD_TOO_SHORT = 'PASSWORD_TOO_SHORT',
  INVALID_PASSWORD = 'INVALID_PASSWORD',
  PASSWORD_NOT_CHANGED = 'PASSWORD_NOT_CHANGED',
  TOO_MANY_REQUESTS = 'TOO_MANY_REQUESTS',
  INVALID_GOOGLE_APP_LICENSE = 'INVALID_GOOGLE_APP_LICENSE',
  NOT_IN_ONEROSTER_SERVER = 'NOT_IN_ONEROSTER_SERVER',
  USERNAME_ALREADY_IN_USE = 'USERNAME_ALREADY_IN_USE',
  USERNAME_TOO_LONG = 'USERNAME_TOO_LONG',
  USER_IS_ALREADY_A_COLLABORATOR = 'USER_IS_ALREADY_A_COLLABORATOR',
  CANNOT_ADD_COLLABORATOR_TO_INACTIVE_PADLET = 'CANNOT_ADD_COLLABORATOR_TO_INACTIVE_PADLET',
  CANNOT_INVITE_SCHOOL_COLLABORATOR = 'CANNOT_INVITE_SCHOOL_COLLABORATOR',
  CANNOT_INVITE_NON_SCHOOL_COLLABORATOR = 'CANNOT_INVITE_NON_SCHOOL_COLLABORATOR',
  CANNOT_INVITE_NON_TENANT_COLLABORATOR = 'CANNOT_INVITE_NON_TENANT_COLLABORATOR',
  CANNOT_INVITE_NEW_USER_ON_NON_NATIVE_PADLET = 'CANNOT_INVITE_NEW_USER_ON_NON_NATIVE_PADLET',
  DRAFT_PUBLISH_FAILED = 'DRAFT_PUBLISH_FAILED',
  UPDATE_FAILED = 'UPDATE_FAILED',
  NEED_TO_VERIFY_IDENTITY = 'NEED_TO_VERIFY_IDENTITY',

  // Email Address Verification
  UNSUPPORTED_DOMAIN = 'UNSUPPORTED_DOMAIN',
  INCORRECT_VERIFICATION_CODE = 'INCORRECT_VERIFICATION_CODE',

  // Transfer wall
  EXCEEDS_WALL_QUOTA_LIMIT = 'EXCEEDS_WALL_QUOTA_LIMIT',
  NOT_WALL_BUILDER = 'NOT_WALL_BUILDER',
  NO_DESTINATION_PERMISSION = 'NO_DESTINATION_PERMISSION',

  // Library Oauth Settings
  LAST_OAUTH_PROVIDER = 'LAST_OAUTH_PROVIDER',
  CLEVER_DISTRICT_ID_ALREADY_IN_USE = 'CLEVER_DISTRICT_ID_ALREADY_IN_USE',
  CLASSLINK_TENANT_ID_ALREADY_IN_USE = 'CLASSLINK_TENANT_ID_ALREADY_IN_USE',

  // Skolon
  SKOLON_ACCOUNT_ALREADY_LINKED = 'SKOLON_ACCOUNT_ALREADY_LINKED',
  SKOLON_SUBSCRIPTION_CREATION_FAILED = 'SKOLON_SUBSCRIPTION_CREATION_FAILED',

  // Downloading zip attachments
  STILL_PROCESSING = 'STILL_PROCESSING',
  DOWNLOAD_FAILED = 'DOWNLOAD_FAILED',
}

// Cancellation type used in personal and library billing
enum CancellationType {
  EndSubscription = 'end_subscription',
  SetAsNonRenewing = 'set_as_non_renewing',
}

// Library
enum LibraryType {
  Personal = 'personal',
  Solo = 'solo',
  Team = 'team',
  School = 'school',
  Classroom = 'classroom',
}

enum LibraryMembershipRole {
  Owner = 'owner',
  Admin = 'admin',
  Maker = 'maker',
  Teacher = 'teacher',
  Student = 'student',
  Contributor = 'contributor',
  Guest = 'guest',
}

enum LibraryMembershipStatus {
  Added = 'added',
  Deactivated = 'deactivated',
  Suspended = 'suspended',
  Left = 'left',
}

enum LibrarySubscriptionStatus {
  OnTrial = 'OnTrial',
  TrialExpired = 'TrialExpired',
  Cancelled = 'Cancelled',
  Paying = 'Paying',
}

enum LibraryMembershipTier {
  Trial = 'trial',
  Neon = 'neon',
  Gold = 'gold',
}

// Notifications

enum NotificationChannelType {
  Push = 'push',
  Email = 'email',
}

enum NotificationType {
  InvitationToCollaborate = 'invitation_to_collaborate',
  Mentioned = 'mentioned',
  PostApprovedDeclined = 'post_approved_declined',
  CommentOnWrittenPost = 'comment_on_written_post',
  ReactionOnWrittenPost = 'reaction_on_written_post',
  NewPostSubmission = 'new_post_submission',
  NewCommentSubmission = 'new_comment_submission',
  PadletRemade = 'padlet_remade',
  PadletFollowed = 'padlet_followed',
  FollowedPadletPost = 'followed_padlet_post',
  FollowedPadletComment = 'followed_padlet_comment',
  FollowedPadletReaction = 'followed_padlet_reaction',
  FollowedAccountNewPadlet = 'followed_account_new_padlet',
  NewFollower = 'new_follower',
  ProductUpdates = 'product_updates',
}

// Surface filters
enum SurfaceFilter {
  All = 'All',
  Published = 'Published',
  Submitted = 'Submitted',
}

// Widgets

enum SnackbarNotificationType {
  success = 'success',
  error = 'error',
  spinner = 'spinner',
}

enum SnackbarLoadingStatus {
  loading = 'loading',
  success = 'success',
  error = 'error',
}

// Padlet privacy
// kebab-cased wall privacy options
// Use WallPrivacyOption instead
enum WallPrivacy {
  OrgWide = 'org-wide', // org-wide and org-wide-unlisted are the same thing, org-wide is kept for compatibility with the old mobile app
  OrgWideUnlisted = 'org-wide-unlisted',
  OrgWideListed = 'org-wide-listed',
  LibraryWideUnlisted = 'library-wide-unlisted',
  LibraryWideListed = 'library-wide-listed',
  Secret = 'secret', // Visitor can write
  SecretRead = 'secret-read', // Visitor can read
  LoggedInUsersOnly = 'logged-in-users_only',
  Private = 'private',
  PasswordProtected = 'password-protected',
  Public = 'public',
}

// Padlet privacy
// snake-cased wall privacy options. TODO: PRO-5447 Standardise privacy option casing
// Use this instead of WallPrivacy
enum WallPrivacyOption {
  OrgWide = 'org_wide', // org-wide and org-wide-unlisted are the same thing, org-wide is kept for compatibility with the old mobile app
  OrgWideUnlisted = 'org_wide_unlisted',
  OrgWideListed = 'org_wide_listed',
  LibraryWideUnlisted = 'library_wide_unlisted',
  LibraryWideListed = 'library_wide_listed',
  Secret = 'secret', // Visitor can write
  SecretRead = 'secret_read', // Visitor can read
  LoggedInUsersOnly = 'logged_in_users_only',
  Private = 'private',
  PasswordProtected = 'password_protected',
  Public = 'public',
}

enum WallPermission {
  Read = 'read',
  Write = 'write',
  None = 'none',
}

// Tenant Permissions
enum BackpackStudentPermission {
  StudentDefaultWallPrivacy = 'studentDefaultWallPrivacy',
  StudentDefaultWallPermission = 'studentDefaultWallPermission',
  CanStudentsMakeWalls = 'canStudentsMakeWalls',
  CanStudentsMakePrivateWalls = 'canStudentsMakePrivateWalls',
  CanStudentsMakeOrgWideHiddenWalls = 'canStudentsMakeOrgWideHiddenWalls',
  CanStudentsMakeOrgWideDashboardWalls = 'canStudentsMakeOrgWideDashboardWalls',
  CanStudentsMakePasswordWalls = 'canStudentsMakePasswordWalls',
  CanStudentsMakeSecretWalls = 'canStudentsMakeSecretWalls',
  CanStudentsViewGallery = 'canStudentsViewGallery',
}

enum BackpackTeacherPermission {
  TeacherDefaultWallPrivacy = 'teacherDefaultWallPrivacy',
  TeacherDefaultWallPermission = 'teacherDefaultWallPermission',
  CanTeachersMakeWalls = 'canTeachersMakeWalls',
  CanTeachersMakePrivateWalls = 'canTeachersMakePrivateWalls',
  CanTeachersMakeOrgWideHiddenWalls = 'canTeachersMakeOrgWideHiddenWalls',
  CanTeachersMakeOrgWideDashboardWalls = 'canTeachersMakeOrgWideDashboardWalls',
  CanTeachersMakePasswordWalls = 'canTeachersMakePasswordWalls',
  CanTeachersMakeSecretWalls = 'canTeachersMakeSecretWalls',
  CanTeachersAddNewUsers = 'canTeachersAddNewUsers',
  CanTeachersEditUsers = 'canTeachersEditUsers',
}

// Grading
enum GradeCategory {
  Score = 'Score',
  Comment = 'Comment',
}

enum Gradable {
  Wish = 'Wish',
  Wall = 'Wall',
}

// School Library Permissions
enum SchoolStudentPermissionBoolean {
  CanMakeWalls = 'canMakeWalls',
  CanMakePrivateWalls = 'canMakePrivateWalls',
  CanMakeLibraryWideHiddenWalls = 'canMakeLibraryWideHiddenWalls',
  CanMakeLibraryWideDashboardWalls = 'canMakeLibraryWideDashboardWalls',
  CanMakePasswordWalls = 'canMakePasswordWalls',
  CanMakeSecretLoggedInVisitorsWalls = 'canMakeSecretLoggedInVisitorsWalls',
  CanMakeSecretAllVisitorsWalls = 'canMakeSecretAllVisitorsWalls',
  CanMakePublicWalls = 'canMakePublicWalls',
  CanViewGallery = 'canViewGallery',
  CanAccessNonSchoolLibraries = 'canAccessNonSchoolLibraries',
  CanAccessWallsFromOutsideSchool = 'canAccessWallsFromOutsideSchool',
  CanInviteCollaboratorsFromOutsideSchool = 'canInviteCollaboratorsFromOutsideSchool',
  CanTransferWalls = 'canTransferWalls',
}
enum SchoolPermissionString {
  DefaultWallPrivacy = 'defaultWallPrivacy',
  DefaultWallPermission = 'defaultWallVisitorPermission',
}

const SchoolStudentPermission = { ...SchoolPermissionString, ...SchoolStudentPermissionBoolean }

enum SchoolTeacherPermissionBoolean {
  CanMakeWalls = 'canMakeWalls',
  CanMakePrivateWalls = 'canMakePrivateWalls',
  CanMakeLibraryWideHiddenWalls = 'canMakeLibraryWideHiddenWalls',
  CanMakeLibraryWideDashboardWalls = 'canMakeLibraryWideDashboardWalls',
  CanMakePasswordWalls = 'canMakePasswordWalls',
  CanMakeSecretLoggedInVisitorsWalls = 'canMakeSecretLoggedInVisitorsWalls',
  CanMakeSecretAllVisitorsWalls = 'canMakeSecretAllVisitorsWalls',
  CanMakePublicWalls = 'canMakePublicWalls',
  CanInviteNewStudents = 'canInviteNewStudents',
  CanInviteNewTeachers = 'canInviteNewTeachers',
  CanAccessNonSchoolLibraries = 'canAccessNonSchoolLibraries',
  CanAccessWallsFromOutsideSchool = 'canAccessWallsFromOutsideSchool',
  CanInviteCollaboratorsFromOutsideSchool = 'canInviteCollaboratorsFromOutsideSchool',
  CanTransferWalls = 'canTransferWalls',
}

const SchoolTeacherPermission = { ...SchoolPermissionString, ...SchoolTeacherPermissionBoolean }

enum ModerationCategories {
  Sexual = 'sexual',
  Hate = 'hate',
  Violence = 'violence',
  Bullying = 'bullying',
  Weapons = 'weapons',
  ChildExploitation = 'child_exploitation',
  ChildSafety = 'child_safety',
  SelfHarm = 'self_harm',
  Profanity = 'profanity',
  Drugs = 'drugs',
}

// Wall access

enum WallAccessPrivacyType {
  Private = 'private',
  Public = 'public',
  Secret = 'secret',
  PasswordProtected = 'password_protected',
  LoggedInUsersOnly = 'logged_in_users_only',
  OrgWideUnlisted = 'org_wide_unlisted',
  OrgWideListed = 'org_wide_listed',
  LibraryWideUnlisted = 'library_wide_unlisted',
  LibraryWideListed = 'library_wide_listed',
}

enum WallAccessRight {
  None = 'none',
  Read = 'read',
  Write = 'write',
  Edit = 'edit',
  Administer = 'administer',
}

// Corresponds to services/rails/app/services/wall_access/role.rb
enum WallAccessRole {
  None = 'none',
  Reader = 'reader',
  Commenter = 'commenter',
  Writer = 'writer',
  Moderator = 'moderator',
  Admin = 'admin',
}

enum WallCollaboratorStatus {
  Requested = 'requested',
  Invited = 'invited',
  Approved = 'approved',
}

enum CommandLevel {
  Padlet = 'Padlet',
  Library = 'Library',
  Account = 'Account',
}

// must match ContentModeration::PostAction::Result in app/services/content_moderation/post_action.rb
enum ParamPostModerationActionResult {
  APPROVE_SUCCESS = 'APPROVE_SUCCESS',
  REJECT_SUCCESS = 'REJECT_SUCCESS',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  MODERATE_FAIL = 'MODERATE_FAIL',
}

// must match ContentModeration::CommentAction::Result in app/services/content_moderation/comment_action.rb
enum ParamCommentModerationActionResult {
  APPROVE_SUCCESS = 'APPROVE_SUCCESS',
  REJECT_SUCCESS = 'REJECT_SUCCESS',
  LOGIN_REQUIRED = 'LOGIN_REQUIRED',
  MODERATE_FAIL = 'MODERATE_FAIL',
}

enum WallVizEnum {
  Canvas = 'free',
  Grid = 'matrix',
  Stream = 'stream',
  Wall = 'grid',
  Map = 'map',
  TimelineV2 = 'timeline_v2',
  Whiteboard = 'whiteboard',
  // Legacy types
  Timeline = 'timeline',
  /** @deprecated Now is "Wall with sections". */
  Shelf = 'shelf',
  /** @deprecated No longer exists. */
  Table = 'table',
}

enum WallVizName {
  Wall = 'Wall',
  Stream = 'Stream',
  Grid = 'Grid',
  Map = 'Map',
  Canvas = 'Canvas',
  Timeline = 'Timeline',
}

enum WallGradeCalculationMethods {
  'Highest' = 'highest',
  'Average' = 'average',
}

enum LmsSyncState {
  AwaitingFirstSync = 'awaiting_first_sync',
  Failed = 'failed',
  Synced = 'synced',
  NeedsSync = 'needs_sync',
  Loading = 'loading',
}

enum UserSyncState {
  Failed = 'failed',
  Synced = 'synced',
  Skipped = 'skipped',
}

enum ContentModerationCategory {
  Sexual = 'sexual',
  Violence = 'violence',
  Bullying = 'bullying',
  Weapons = 'weapons',
  Drugs = 'drugs',
  Profanity = 'profanity',
  ChildSafety = 'child_safety',
}

enum ContentModerationMode {
  None = 'none',
  Auto = 'auto',
  StudentsOnly = 'students_only',
  All = 'all',
}

enum ContentSafetyEntity {
  Library = 'library',
  Tenant = 'tenant',
}

enum ThirdPartyRosteringServiceOptions {
  Clever = 'Clever',
  Classlink = 'Classlink',
}

enum SurfaceOnboardingActionTypes {
  OpenSharePanel = 'Open share panel',
  OpenSettingsPanel = 'Open settings panel',
  EditCustomFields = 'Edit custom fields',
  GenerateSamplePosts = 'Generate sample posts',
  OpenSlideshow = 'Open slideshow',
  OpenActivityPanel = 'Open activity panel',
  EditModerationSettings = 'Edit moderation settings',
  GetBrowserExtension = 'Get the browser extension',
  CopySubmissionLink = 'Copy submission link',
  SetUpBreakoutLinks = 'Set up breakout links',
  CustomizeAttachments = 'Customize attachments',
}

enum WallCreatedFromType {
  gallery = 'gallery_template',
  guided_template = 'guided_template',
  demo = 'demo',
  blank = 'blank',
}

enum DemoPadletPanelStep {
  Landing = 'landing',
  Topic = 'topic',
  Post = 'post',
  Share = 'share',
  Done = 'done',
  LearnMore = 'learnmore',
  ShareAndPrivacy = 'shareandprivacy',
  Personalize = 'personalize',
  PresentAsSlideshow = 'presentaslideshow',
}

enum DiscussionStyle {
  QuestionAndResponse = 'question_and_response',
  Debate = 'debate',
}

enum DiscussionResponseStand {
  For = 'for',
  Against = 'against',
}

enum GuidedTemplateType {
  DiscussionBoard = 'discussion_board',
  ExitTicket = 'exit_ticket',
}

enum GuidedSubpanel {
  Generate = 'generate',
  Configure = 'configure',
  Post = 'post',
  Share = 'share',
}

// From UserAccountType::VALID_ACCOUNT_TYPES
enum UserAccountType {
  PERSONAL = 'personal',
  STUDENT = 'student',
  TEACHER = 'teacher',
  STAFF = 'staff',
  BUSINESS = 'business',
}

// Google App Licensing enums

enum GoogleAppLicensingSkuName {
  STUDENT = 'padlet-student',
  TEACHER = 'padlet-teacher',
  ADMIN = 'padlet-admin',
}

enum DelinquentCardStatus {
  Valid = 'valid',
  Expiring = 'expiring',
  Expired = 'expired',
}

// Must match the values of PersonalPricingPlansExperiment::VALID_VARIANTS
enum PricingPlansExperimentVariant {
  AnnualOnly = 'annual_only',
  PlatinumOnly = 'platinum_only',
  PlatinumSilverAnnualOnly = 'platinum_silver_annual_only',
  Control = 'control',
}

// Must match the values of ClassroomPlanPricingExperiment::VALID_VARIANTS
enum ClassroomPlanPricingExperimentVariant {
  Control = 'control',
  SingleLicense = 'single_license',
}

enum FetchJsonStatus {
  NotStarted = 'Not_Started',
  Fetching = 'Fetching',
  Completed = 'Completed',
  Error = 'Error',
}

enum OrizuruColor {
  Canary = 'canary',
  Grape = 'grape',
  Grey = 'grey',
  Oceanic = 'oceanic',
  PadletPink = 'padlet-pink',
  Park = 'park',
  Scarlet = 'scarlet',
  Tangerine = 'tangerine',
  Teal = 'teal',
}

// Library Members Page enums
enum LibraryMembersInviteModalPage {
  Link = 'link',
  Email = 'email',
  Csv = 'csv',
}

// Whiteboard shown cursor type
// Keep in sync with whiteboard_collaboration_settings.rb#COLLABORATION_CURSORS
enum ShownCursorType {
  ALL = 'all',
  NONE = 'none',
}

const MagicTemplate: Record<string, MagicTemplate> = {
  DiscussionBoard: {
    name: __('Discussion board'),
    key: 'discussion_board',
    icon: 'compass',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_discussion_board.webp',
    originalImageWidth: 100,
    originalImageHeight: 100,
    isNew: true,
  },
  ...(isAppUsing('exitTicket') && {
    ExitTicket: {
      name: p__(
        "An Exit ticket is an informal activity at the end of class used to quickly assess students' understanding of lesson objectives. Students must submit their responses or reflections before leaving, like a ticket needed to exit the classroom",
        'Exit ticket',
      ),
      key: 'exit_ticket',
      icon: 'ticket',
      imageSrc: 'https://padlet.net/dashboard/v0_1/ai_exit_ticket.webp',
      originalImageWidth: 168,
      originalImageHeight: 168,
      isNew: true,
    },
  }),
  LessonPlan: {
    name: __('Lesson plan'),
    key: 'lesson_plan',
    icon: 'note_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_lesson_plan.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  ClassActivities: {
    name: __('Class activity creator'),
    key: 'class_activities',
    icon: 'pencil_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_class_activity_creator.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
    isNew: true,
  },
  ClassroomActivitiesList: {
    name: __('Ideas for class activities'),
    key: 'classroom_activity_ideas',
    icon: 'lightbulb',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_classroom_activities.webp',
    originalImageWidth: 100,
    originalImageHeight: 100,
    shortName: __('Class activities'),
  },
  HistoricalTimeline: {
    name: __('Timeline of events'),
    key: 'historical_timeline',
    icon: 'recent_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_timeline.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  ReadingList: {
    name: __('Reading list'),
    key: 'reading_list',
    icon: 'library',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_reading_list.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  MapOfHistoricalEvents: {
    name: __('Map of historical events'),
    key: 'map_of_historical_events',
    icon: 'map_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_map.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
    shortName: __('Historical map'),
  },
  LearningAssessmentPolls: {
    name: __('Assessment polls'),
    key: 'learning_assessment_polls',
    icon: 'poll_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_assessment_polls.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  Rubric: {
    name: __('Rubric'),
    key: 'rubric',
    icon: 'grade_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_rubric.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
  CustomBoard: {
    name: __('Custom board'),
    key: 'custom_board',
    icon: 'sparkles_outline',
    imageSrc: 'https://padlet.net/dashboard/v0_1/ai_custom_board.webp',
    originalImageWidth: 168,
    originalImageHeight: 168,
  },
}

enum AccessibilitySettingPreference {
  System = 'system',
  On = 'on',
  Off = 'off',
}

enum WallWishGroupByTypes {
  None = 'none',
  Section = 'section',
  Author = 'author',
  Color = 'color',
}

enum WallWishSortByTypes {
  ManualNewPostsFirst = 'manual_new_posts_first',
  ManualNewPostsLast = 'manual_new_posts_last',
  ReactionsAsc = 'reactions_asc',
  ReactionsDesc = 'reactions_desc',
  DatePublishedSortAsc = 'date_published_asc',
  DatePublishedSortDesc = 'date_published_desc',
  PostSubjectAsc = 'post_subject_asc',
  PostSubjectDesc = 'post_subject_desc',
  Shuffle = 'shuffle',
}

export {
  AccessibilitySettingPreference,
  ApiErrorCode,
  BackpackStudentPermission,
  BackpackTeacherPermission,
  CancellationType,
  CommandLevel,
  ContentModerationCategory,
  ContentModerationMode,
  ContentSafetyEntity,
  DelinquentCardStatus,
  DemoPadletPanelStep,
  DiscussionResponseStand,
  DiscussionStyle,
  FetchJsonStatus,
  GoogleAppLicensingSkuName,
  Gradable,
  GradeCategory,
  GuidedSubpanel,
  GuidedTemplateType,
  HttpCode,
  LibraryMembershipRole,
  LibraryMembershipStatus,
  LibraryMembershipTier,
  LibraryMembersInviteModalPage,
  LibrarySubscriptionStatus,
  LibraryType,
  LmsSyncState,
  MagicTemplate,
  ModerationCategories,
  NotificationChannelType,
  NotificationType,
  OrizuruColor,
  ParamCommentModerationActionResult,
  ParamPostModerationActionResult,
  PricingPlansExperimentVariant,
  ClassroomPlanPricingExperimentVariant,
  SchoolPermissionString,
  SchoolStudentPermission,
  SchoolStudentPermissionBoolean,
  SchoolTeacherPermission,
  SchoolTeacherPermissionBoolean,
  ShownCursorType,
  SnackbarLoadingStatus,
  SnackbarNotificationType,
  SurfaceFilter,
  SurfaceOnboardingActionTypes,
  ThirdPartyRosteringServiceOptions,
  UserAccountType,
  UserSyncState,
  WallAccessPrivacyType,
  WallAccessRight,
  WallAccessRole,
  WallCollaboratorStatus,
  WallCreatedFromType,
  WallGradeCalculationMethods,
  WallPermission,
  WallPrivacy,
  WallPrivacyOption,
  WallVizEnum,
  WallVizName,
  WallWishGroupByTypes,
  WallWishSortByTypes,
}
